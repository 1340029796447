import React, { Component, Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Routes from "./Routes";
import "./App.css";
import navlogo from "./images/icon_white.png";
import { withRouter } from "react-router-dom";

class App extends Component<any, any> {
  constructor(props: any) {
    super(props);
  
    this.state = {
      isMobile: false
    };
  }
  
  handleWindowResize = () => {
    this.setState({ isMobile: window.innerWidth < 1024 });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
}
  
  render() {
    const year = (new Date()).getFullYear();
    const childProps = {};
    return (
      <div className="App">
        <nav id="mainNav" className="navbar navbar-expand-lg fixed-top text-uppercase">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
              {this.state.isMobile ?
              <img src={navlogo} alt="logo" style={{ verticalAlign:"text-top", paddingTop:"5px" }} />
              :
              <Fragment><img src={navlogo} className="hidden-sm hidden-xs" alt="logo" style={{ verticalAlign:"text-top", paddingTop:"5px" }} /><span> KenzenSoft</span></Fragment>
              }
            </a>
            <button className="navbar-toggler navbar-toggler-right text-uppercase bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span>Menu</span>
              <i className="fas fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mx-0 mx-lg-1">
                    <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#portfolio">Products</a>
                </li>
                <li className="nav-item mx-0 mx-lg-1">
                    <a className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" href="#about">About</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Routes childProps={childProps} />
        <div className="scroll-to-top d-lg-none position-fixed " style={{ display: "block", marginBottom: "40px" }}>
          <a className="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i className="fas fa-chevron-up"></i>
          </a>
        </div>
        <Navbar id="footerNav" className="navbar-dark fixed-bottom" expand="sm" style={{ color: "#000c23", backgroundColor: "#000c23"}}>
          <div className="container d-flex justify-content-between">
            <span className="text-white">&copy; {year} KenZenSoft Inc.</span>
              <Nav className="mr-auto">
              </Nav>
              <Nav.Link className="kzs-navlink" target="_blank" href="mailto:connect@kenzensoft.com">Contact Us</Nav.Link>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(App);
