import React from "react";
import Loadable from 'react-loadable';
import { Route, Switch } from "react-router-dom";

const MyLoadingComponent = ({isLoading, error}: any) => {
  // Handle the loading state
  if (isLoading) {
    return <div>Loading...</div>;
  }
  // Handle the error state
  else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  }
  else {
    return null;
  }
};

const AsyncHome = Loadable(
  {
    loader: () => import("./containers/Home"),
    loading: MyLoadingComponent
  });
const AsyncPrivacy = Loadable(
  {
    loader: () => import("./containers/Privacy"),
    loading: MyLoadingComponent
  });
const AsyncNotFound = Loadable(
  {
    loader: () => import("./containers/NotFound"),
    loading: MyLoadingComponent
  });
const AsyncTerms = Loadable(
  {
    loader: () => import("./containers/Terms"),
    loading: MyLoadingComponent
  });

export default ({ childProps }: any) =>
  <Switch>
    <Route path="/" exact component={AsyncHome} props={childProps} />
    <Route path="/privacy" exact component={AsyncPrivacy} props={childProps} />
    <Route path="/terms" exact component={AsyncTerms} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={AsyncNotFound} />
  </Switch>;
